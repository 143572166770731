<template>
  <main>
    <HomeHeader></HomeHeader>
    <About></About>

    <div class="w-100 about-mint-spacer"></div>

    <Mint></Mint>
    <Customizer></Customizer>
    <Roadmap></Roadmap>

    <FooterMain></FooterMain>
  </main>
</template>

<script>
import HomeHeader from './../components/HomeHeader'
import About from './../components/About'
import Mint from './../components/Mint'
import Roadmap from './../components/Roadmap'
import Customizer from './../components/Customizer'
import FooterMain from './../components/Footer'

export default {
  name: 'Home',
  components: {
    HomeHeader,
    About,
    Mint,
    Customizer,
    Roadmap,
    FooterMain
  },
  props: {},
  data() {
    return {}
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
