<template>
  <div class="stake-modal" v-if="$root.breedInfoModalVisible && item">
    <div class="stake-modal__overlay" @click.self="closeModal()">
      <div class="stake-modal__body">
        <button class="button button--close" @click="closeModal()"></button>
        <section class="stake-modal__content">
          <div class="stake-modal__image">
            <img :src="item.img" alt="item picture" />
          </div>
          <div class="stake-modal__info">
            <div class="stake-modal__details mb-0">
              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">ID</p>
                <span class="stake-modal__detail-value" v-html="item.id"></span>
              </div>
              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">Rewards</p>
                <span class="stake-modal__detail-value" v-html="item.rewards"></span>
              </div>
              <div class="stake-modal__detail" v-for="(attribute, i) in item.attributes" :key="i">
                <p class="stake-modal__detail-name" v-html="attribute.name"></p>
                <div class="stake-modal__values">
                  <span class="stake-modal__detail-value" v-html="attribute.value"></span>
                  <span
                    class="stake-modal__trait"
                    v-html="attribute.rare + ' have this trait'"
                  ></span>
                </div>
              </div>
              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">Harvest</p>
                <span class="stake-modal__trait" v-html="item.harvest"></span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreedInfoModal',
  data() {
    return {}
  },
  props: {
    item: Object
  },
  computed: {},
  created() {
    document.addEventListener('keyup', evt => {
      if (evt.key === 27) {
        this.closeModal()
      }
    })
  },
  methods: {
    closeModal() {
      this.$root.breedInfoModalVisible = false
    }
  },
  watch: {},
  components: {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {}
}
</script>

<style lang="scss">
</style>
