<template>
  <div>
    <div class="customizer-spacer"></div>

    <div id="customizer" class="pt-5 pb-5">
      <div class="container pb-5">
        <div class="row pb-5">
          <div class="col-lg-6 col-md-12 order-2 order-lg-1">
            <div class="tabs">
              <ul id="tab-links" class="mb-0">
                <li>
                  <div id="nomity-tab-switch" class="active" @click="switchChTab('nomity-tab')">
                    Nomity
                  </div>
                </li>
                <li>
                  <div
                    id="background-tab-switch"
                    href="#bg-tab"
                    @click="switchChTab('background-tab')"
                  >
                    Background
                  </div>
                </li>
                <li>
                  <div id="weapon-tab-switch" href="#weapon-tab" @click="switchChTab('weapon-tab')">
                    Weapon
                  </div>
                </li>
                <li>
                  <div id="shield-tab-switch" href="#shield-tab" @click="switchChTab('shield-tab')">
                    Shield
                  </div>
                </li>
                <li>
                  <div id="head-tab-switch" href="#head-tab" @click="switchChTab('head-tab')">
                    Head
                  </div>
                </li>
                <li>
                  <div
                    id="glasses-tab-switch"
                    href="#glasses-tab"
                    @click="switchChTab('glasses-tab')"
                  >
                    Glasses
                  </div>
                </li>
                <li>
                  <div id="scarf-tab-switch" href="#scarf-tab" @click="switchChTab('scarf-tab')">
                    Scarf
                  </div>
                </li>
                <li>
                  <div id="tshirt-tab-switch" href="#tshirt-tab" @click="switchChTab('tshirt-tab')">
                    T-Shirt
                  </div>
                </li>
                <li>
                  <div id="belt-tab-switch" href="#belt-tab" @click="switchChTab('belt-tab')">
                    Belt
                  </div>
                </li>
                <li>
                  <div
                    id="trousers-tab-switch"
                    href="#trousers-tab"
                    @click="switchChTab('trousers-tab')"
                  >
                    Trousers
                  </div>
                </li>
              </ul>

              <section id="nomity-tab" class="active w-100 overflow-auto" ref="nomityTab"></section>
              <section id="background-tab" class="w-100"></section>
              <section id="weapon-tab" class="w-100" ref="weaponTab"></section>
              <section id="shield-tab" class="w-100" ref="shieldTab"></section>
              <section id="head-tab" class="w-100" ref="headTab"></section>
              <section id="glasses-tab" class="w-100"></section>
              <section id="scarf-tab" class="w-100"></section>
              <section id="tshirt-tab" class="w-100"></section>
              <section id="belt-tab" class="w-100"></section>
              <section id="trousers-tab" class="w-100"></section>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 order-1 order-lg-2">
            <div class="generated-bg-block" ref="generatedBg">
              <div id="ch-generated" class="mx-auto" style="max-width: 300px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import characters from '@/assets/characters.json'
import metadata_naming from '@/assets/metadata_naming.json'

export default {
  name: 'Customizer',
  data() {
    return {
      characters: characters,
      metadata: metadata_naming,
      background: null,
      nomity: null,
      weapon: null,
      shield: null,
      head: null,
      glasses: null,
      scarf: null,
      tshirt: null,
      belt: null,
      trousers: null,
      activeChTab: 'nomity-tab'
    }
  },
  created: function () {
    this.background = this.characters.background[0]
    this.nomity = this.characters.nomity[0]
    this.weapon = this.characters.weapon[0]
    this.shield = this.characters.shield[0]
    this.head = this.characters.head[0]
    this.glasses = this.characters.glasses[0]
    this.scarf = this.characters.scarf[0]
    this.tshirt = this.characters.tshirt[0]
    this.belt = this.characters.belt[0]
    this.trousers = this.characters.trousers[0]
  },
  mounted: function () {
    this.resizeChTabs()
    this.fillActiveChTab(this.activeChTab)
    this.renderCharacter()
  },
  methods: {
    resizeChTabs: function () {
      let mainHeight = this.$refs.nomityTab.clientHeight

      this.$refs.weaponTab.style.height = mainHeight + 'px'
      this.$refs.shieldTab.style.height = mainHeight + 'px'
      this.$refs.headTab.style.height = mainHeight + 'px'
    },
    switchChTab: function (tabName) {
      let currentTab = document.getElementById(this.activeChTab)
      let targetTab = document.getElementById(tabName)

      let currentSwitch = document.getElementById(this.activeChTab + '-switch')
      let targetSwitch = document.getElementById(tabName + '-switch')

      currentTab.setAttribute('class', '')
      targetTab.setAttribute('class', 'active w-100 overflow-auto')

      this.fillActiveChTab(tabName)

      currentSwitch.setAttribute('class', '')
      targetSwitch.setAttribute('class', 'active')

      this.activeChTab = tabName
    },
    handleAttrClick: function (attrsDir, attrName) {
      this.replaceActiveClassCh(attrsDir)
      this[attrsDir] = attrName

      this.addActiveClassCh(attrName)
      this.renderCharacter()
    },
    fillActiveChTab: function (tabName) {
      let chTab = document.getElementById(tabName)

      chTab.innerHTML = ''
      chTab.style.backgroundColor = 'black'

      let attrsDir = tabName.split('-')[0]

      let div = document.createElement('div')
      div.setAttribute('class', 'row')

      for (let attrName of this.characters[attrsDir]) {
        let col = document.createElement('div')
        col.setAttribute('class', 'col-md-4 col-attr-cell')

        let pic = document.createElement('img')

        let active = ''

        if (this[attrsDir] == attrName) {
          active = ' active-ch-cell'
        }

        pic.setAttribute('class', 'img-fluid mx-2 my-2' + active)

        pic.style.backgroundImage = 'url("' + require('@/assets/nomity_transparent.png') + '")'
        pic.style.backgroundSize = 'contain'
        pic.style.cursor = 'pointer'
        pic.id = attrName
        pic.src = require('@/assets/generator/' + attrsDir + '/' + attrName + '.png')

        let funcVar = this.handleAttrClick
        pic.addEventListener(
          'click',
          (function (attrsDir, attrName, funcVar) {
            return function () {
              funcVar(attrsDir, attrName)
            }
          })(attrsDir, attrName, funcVar)
        )

        col.appendChild(pic)

        div.appendChild(col)
      }

      chTab.appendChild(div)
    },
    addActiveClassCh: function (attrId) {
      let pic = document.getElementById(attrId)
      pic.setAttribute('class', 'active-ch-cell img-fluid mx-2 my-2')
    },
    replaceActiveClassCh: function (attrsDir) {
      let pic = document.getElementById(this[attrsDir])
      pic.setAttribute('class', 'img-fluid mx-2 my-2')
    },
    renderCharacter: function () {
      let image = document.getElementById('ch-generated')
      image.innerHTML = ''

      if (this.background != null) {
        let background = document.createElement('img')
        background.setAttribute('class', 'img-fluid position-absolute ch-generated-border')
        background.src = require('@/assets/generator/background/' + this.background + '.png')

        image.appendChild(background)
      }

      if (this.nomity != null) {
        let nomity = document.createElement('img')
        nomity.setAttribute('class', 'img-fluid position-absolute')
        nomity.src = require('@/assets/generator/nomity/' + this.nomity + '.png')

        image.appendChild(nomity)
      }

      if (this.trousers != null && this.trousers != 'empty_trousers') {
        let trousers = document.createElement('img')
        trousers.setAttribute('class', 'img-fluid position-absolute')
        trousers.src = require('@/assets/generator/trousers/' + this.trousers + '.png')

        image.appendChild(trousers)
      }

      if (this.tshirt != null && this.tshirt != 'empty_tshirt') {
        let tshirt = document.createElement('img')
        tshirt.setAttribute('class', 'img-fluid position-absolute')
        tshirt.src = require('@/assets/generator/tshirt/' + this.tshirt + '.png')

        image.appendChild(tshirt)
      }

      if (this.belt != null && this.belt != 'empty_belt') {
        let belt = document.createElement('img')
        belt.setAttribute('class', 'img-fluid position-absolute')
        belt.src = require('@/assets/generator/belt/' + this.belt + '.png')

        image.appendChild(belt)
      }

      if (this.scarf != null && this.scarf != 'empty_scarf') {
        let scarf = document.createElement('img')
        scarf.setAttribute('class', 'img-fluid position-absolute')
        scarf.src = require('@/assets/generator/scarf/' + this.scarf + '.png')

        image.appendChild(scarf)
      }

      if (this.head != null && this.head != 'empty_head') {
        let head = document.createElement('img')
        head.setAttribute('class', 'img-fluid position-absolute')
        head.src = require('@/assets/generator/head/' + this.head + '.png')

        image.appendChild(head)
      }

      if (this.glasses != null && this.glasses != 'empty_glasses') {
        let glasses = document.createElement('img')
        glasses.setAttribute('class', 'img-fluid position-absolute')
        glasses.src = require('@/assets/generator/glasses/' + this.glasses + '.png')

        image.appendChild(glasses)
      }

      if (this.weapon != null && this.weapon != 'empty_weapon') {
        let weapon = document.createElement('img')
        weapon.setAttribute('class', 'img-fluid position-absolute')
        weapon.src = require('@/assets/generator/weapon/' + this.weapon + '.png')

        image.appendChild(weapon)
      }

      if (this.shield != null && this.shield != 'empty_shield') {
        let shield = document.createElement('img')
        shield.setAttribute('class', 'img-fluid position-absolute')
        shield.src = require('@/assets/generator/shield/' + this.shield + '.png')

        image.appendChild(shield)
      }
    }
  }
}
</script>


<style scoped>
#customizer {
  font-family: 'PEPminus10', sans-serif;
  font-size: 1.3rem;
  background: rgb(2, 210, 192);
  background: linear-gradient(0deg, rgba(102, 51, 153, 1) 0%, rgba(2, 210, 192, 1) 100%);
}

.customizer-spacer {
  height: 10vh;
  background-color: #02d2c0;
}

.tabs {
  max-width: 60rem;
  display: flex;
}

.tabs > ul {
  list-style-type: none;
  padding: 0;
  height: 100%;
}

.tabs > ul li {
  display: block;
  text-align: center;
}

.tabs > ul li div {
  display: block;
  text-decoration: none;
  background: #11111a;
  padding: 1rem 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease-in-out;
}
.tabs > ul li div.active {
  background: white;
  color: #2c2c2c;
}

.tabs > ul li div:hover:not(.active) {
  color: #fff;
}

.tabs > ul li {
  cursor: pointer;
}

.tabs section {
  background: #fff;
  padding: 1.5rem 2.5rem;
  display: none;
}

.tabs section.active {
  display: block;
}

section::-webkit-scrollbar {
  width: 4px;
}

section::-webkit-scrollbar-thumb {
  background: rgb(51, 49, 49);
}
</style>