import Vue from 'vue'
import App from './App.vue'
import Helpers from './lib/Helpers.js'
import router from '@/router'
import VueMeta from 'vue-meta'
import './scss/app.scss'
import resize from "vue-element-resize-detector";
import VueScrollTo from "vue-scrollto";

Vue.use(resize)
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  data() {
    return {
      width: null,
      height: null,
      tabletBreakpoint: 1023,
      mobileBreakpoint: 768,
      stakeModalVisible: false,
      breedModalVisible: false,
      breedInfoModalVisible: false,
    }
  },
  computed: {
    isTablet() {
      return this.width <= this.tabletBreakpoint
    },
    isMobile() {
      return this.width <= this.mobileBreakpoint
    },
    modalVisible() {
      return this.stakeModalVisible || this.breedModalVisible || this.breedInfoModalVisible
    }
  },
  watch: {
    modalVisible(newV) {
      if (newV) {
        document.body.classList.add('scroll-off')
      } else {
        document.body.classList.remove('scroll-off')
      }
    }
  },
  async created() {
    this.width = window.innerWidth
    this.height = window.innerHeight
  },
  mounted() {
    this.addListeners()
  },
  methods: {
    addListeners() {
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)
    },
    onResize() {
      this.width = window.innerWidth
      this.height = window.innerHeight
    },
  },
  router,
  render: h => h(App),
}).$mount('#app')