<template>
  <main>
    <StakeHeader></StakeHeader>
    <StakeContent></StakeContent>
    <FooterMain></FooterMain>
  </main>
</template>

<script>
import StakeHeader from './../components/StakeHeader'
import StakeContent from './../components/StakeContent'
import FooterMain from './../components/Footer'

export default {
  name: 'Stake',
  components: {
    StakeHeader,
    StakeContent,
    FooterMain
  },
  props: {},
  data() {
    return {}
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
