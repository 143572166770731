<template>
  <div class="stake-modal" v-if="$root.stakeModalVisible && modalItem">
    <div class="stake-modal__overlay" @click.self="closeModal()">
      <div class="stake-modal__body">
        <button class="button button--close" @click="closeModal()"></button>
        <section class="stake-modal__content">
          <div class="stake-modal__image">
            <img :src="modalItem.img" alt="item picture" />
          </div>
          <div class="stake-modal__info">
            <div class="stake-modal__details">
              <div class="stake-modal__detail" v-if="modalItem.staked">
                <p class="stake-modal__item-staked">Staked</p>
              </div>
              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">ID</p>
                <span class="stake-modal__detail-value" v-html="modalItem.id"></span>
              </div>

              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">Rewards</p>
                <span class="stake-modal__detail-value" v-html="modalItem.rewards"></span>
              </div>

              <div
                class="stake-modal__detail"
                v-for="(attribute, i) in modalItem.attributes"
                :key="i"
              >
                <p class="stake-modal__detail-name" v-html="attribute.name"></p>
                <div class="stake-modal__values">
                  <span class="stake-modal__detail-value" v-html="attribute.value"></span>
                  <span
                    class="stake-modal__trait"
                    v-html="attribute.rare + ' have this trait'"
                  ></span>
                </div>
              </div>
              <div class="stake-modal__detail">
                <p class="stake-modal__detail-name">Harvest</p>
                <span class="stake-modal__trait" v-html="modalItem.harvest"></span>
              </div>
            </div>
            <button
              class="button button--stake"
              v-if="!modalItem.staked"
              @click="stakeToggle(modalItem)"
            >
              Stake
            </button>

            <div class="stake-modal__buttons" v-if="modalItem.staked">
              <button class="button button--unstake" @click="stakeToggle(modalItem)">
                Unstake
              </button>
              <div class="stake-modal__harvest-info">
                <p v-html="modalItem.harvest"></p>
                <button class="button button--harvest">Harvest</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StakeModal',
  data() {
    return {}
  },
  props: {
    modalItem: Object,
    viewStakedItem: Object
  },
  computed: {},

  created() {
    document.addEventListener('keyup', evt => {
      if (evt.key === 27) {
        this.closeModal()
      }
    })
  },
  methods: {
    closeModal() {
      this.$root.stakeModalVisible = false
    },
    stakeToggle(item) {
      item.staked = !item.staked
    }
  },
  watch: {},
  destroyed() {},
  components: {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss">
.stake-modal__overlay {
  position: fixed;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.stake-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.stake-modal__body {
  margin: auto;
  overflow-x: auto;
  width: 68%;
  max-width: 980px;
  background: #4f207e;
  min-width: 928px;
  transition: all 0.3s;

  padding: 80px;

  display: flex;
  position: relative;
  align-items: center;
  max-height: fit-content;

  max-height: calc(100vh - 100px);

  @media (max-width: 1023px) {
    align-items: baseline;
    min-width: 608px;
    max-height: calc(100vh - 65px);
    padding: 80px 84px;
  }

  @media (max-width: 767px) {
    min-width: auto;
    max-height: none;
    width: 100%;
    height: 112vh;
    padding: 80px 24px;
  }

  p,
  span {
    font-family: 'Anonymous Pro', sans-serif;
  }
}

.stake-modal__content {
  display: flex;
  background: #401769;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 40px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 24px;
  }
}

.stake-modal__image {
  width: 432px;
  height: 432px;
  background: #fadbd1;
  border: 8.30769px solid #e44b39;
  box-sizing: border-box;
  box-shadow: inset 0px 8.30769px 8.30769px #000000;
  border-radius: 8px;
  padding: 50px;
  margin-right: 3.5%;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    margin-right: auto;
    margin-left: auto;
    width: 360px;
    height: 360px;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 267px;
    margin: 0 auto;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.stake-modal__buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  transform: translateY(-25px);

  @media (max-width: 1023px) {
    transform: translateY(0);
  }
}

.stake-modal__info {
  width: 43.5%;
  padding: 40px 40px 40px 0;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 40px 0 0 0;
  }

  @media (max-width: 767px) {
    padding: 20px 0 0 0;
  }
}

.stake-modal__details {
  background: #542e79;
  padding: 24px 24px 0 24px;
  min-width: 275px;

  height: 240px;
  overflow: auto;
  margin-bottom: 75px;

  @media (max-width: 1023px) {
    padding: 24px 15px 0 24px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    min-width: auto;
    margin-bottom: 25px;
  }
}

.stake-modal__detail {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #654286;
  padding-bottom: 12px;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.stake-modal__detail-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;

  color: #ffffff;
  margin: 0;
}

.stake-modal__values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.stake-modal__detail-value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;

  text-align: right;
}

.stake-modal__trait {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: right;

  color: #ffffff;

  opacity: 0.5;
}

.stake-modal__item-staked {
  font-family: 'PEPminus10', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 0;

  text-align: center;
  letter-spacing: 0.04em;

  color: #e44b39;
}

.stake-modal__harvest-info {
  display: flex;
  flex-direction: column;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;

    color: #ffffff;

    margin: 0;
    text-align: center;
  }
}
</style>
