<template>
  <div id="__app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { ethers } from 'ethers'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default {
  name: 'App',
  data() {
    return {
      provider: null,
      currMinted: '?',
      totalMinted: 10000,
      walletConnected: false,
      walletAddr: null,
      walletAddrFormatted: null,
      chainId: '0xfa',
      rpcURL: 'https://rpc.ftm.tools/',
      chainName: 'Fantom Opera',
      blockExplorerUrl: 'https://ftmscan.com/',
      currName: 'Fantom',
      currSymbol: 'FTM'
    }
  },
  components: {},
  computed: {
    progress() {
      if (this.currMinted == '?') {
        return 'width: 0%'
      }
      return `width: ${(this.currMinted / this.totalMinted) * 100}%`
    }
  },
  methods: {
    loadMinted: async function () {
      this.currMinted = 1000
    },
    enableWallet: async function (walletAddr) {
      this.walletConnected = true
      this.walletAddr = walletAddr
      this.walletAddrFormatted = this.formatWalletAddr()
      await this.loadMinted()
    },
    disableWallet: function () {
      this.walletConnected = false
      this.walletAddr = null
      this.walletAddrFormatted = null
      this.currMinted = '?'
    },
    mint: async function () {
      console.log('mint')
    },
    handleWalletChange: async function () {
      let addrs = await window.ethereum.request({ method: 'eth_accounts' })

      if (addrs.length != 0 && addrs[0] != this.walletAddr) {
        await this.enableWallet(addrs[0])
      } else if (addrs.length == 0) {
        this.disableWallet()
      }
    },
    handleChainChange: async function () {
      let chainId = await window.ethereum.request({ method: 'eth_chainId' })

      if (chainId != this.chainId) {
        this.disableWallet()
      } else {
        let addrs = await window.ethereum.request({
          method: 'eth_requestAccounts'
        })

        if (addrs.length != 0 && addrs[0] != this.walletAddr) {
          await this.enableWallet(addrs[0])
        }
      }
    },
    formatWalletAddr: function () {
      return '0x' + this.walletAddr.slice(2, 4) + '...' + this.walletAddr.slice(-3)
    },
    connectWallet: async function () {
      if (this.walletConnected) {
        this.disableWallet()

        return
      }

      try {
        if (this.walletAddr != null) return

        let chainId = await window.ethereum.request({ method: 'eth_chainId' })

        if (chainId != this.chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: this.chainId }]
            })
            return
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: this.chainId,
                      rpcUrls: [this.rpcURL],
                      chainName: this.chainName,
                      blockExplorerUrls: [this.blockExplorerUrl],
                      nativeCurrency: {
                        name: this.currName,
                        symbol: this.currSymbol,
                        decimals: 18
                      }
                    }
                  ]
                })
              } catch (addError) {
                //
              }
            }
          }
        }

        let addrs = await window.ethereum.request({
          method: 'eth_requestAccounts'
        })
        if (addrs.length != 0) {
          await this.enableWallet(addrs[0])
        }
      } catch (error) {
        //
      }
    }
  },
  created: async function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum)

    window.ethereum.on('accountsChanged', this.handleWalletChange)
    window.ethereum.on('chainChanged', this.handleChainChange)

    let addrs = await window.ethereum.request({ method: 'eth_accounts' })
    if (window.ethereum.isConnected() && addrs.length != 0) {
      this.enableWallet(addrs[0])
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'PEPminus10';
  font-style: normal;
  font-weight: 400;
  src: local('PEPminus10'), url('./assets/fonts/pepminus10.woff') format('woff');
}

@font-face {
  font-family: 'Almost Serious';
  font-style: normal;
  font-weight: 400;
  src: local('Almost Serious'), url('./assets/fonts/almostserious.woff') format('woff');
}
@font-face {
  font-family: 'Almost Serious Accent';
  font-style: normal;
  font-weight: 400;
  src: local('Almost Serious Accent'), url('./assets/fonts/almostseriousaccent.woff') format('woff');
}

@font-face {
  font-family: 'Anonymous';
  font-style: normal;
  font-weight: 400;
  src: local('Anonymous'), url('./assets/fonts/Anonymous.woff') format('woff');
}

@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Anonymous'), url('./assets/fonts/AnonymousPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 800;
  src: local('Anonymous'), url('./assets/fonts/AnonymousPro-Bold.woff') format('woff');
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'PEPminus10', sans-serif;
}

.global-header {
  background: linear-gradient(180deg, #e44639 0%, #615ec5 100%);
}

.about-mint-spacer {
  background-color: #615ec5;
  height: 10rem;
}

.header-shadow {
  height: 10vh;
}

.main-header {
  background: url('./assets/canyon_large.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  font-family: 'Anonymous', sans-serif;
}

.main-middled {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-nav-button {
  border-width: 3px;
  border-color: black;
  color: black;
  font-weight: bold;
}

.main-nav-button:hover {
  border-width: 3px;
  border-color: black;
  color: white;
  font-weight: bold;
  background-color: #e44639;
}

.connect-button {
  border-color: black;
  border-width: 3px;
  color: black;
  font-weight: bold;
}

.nav-buttons {
  margin: 0;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.simple-text {
  font-size: 1.5rem;
  color: black;
  font-weight: bold;
}

.art-description {
  font-family: 'Anonymous', sans-serif;
}

.lg-chat-text {
  font-size: 1.2rem;
  font-family: 'Anonymous', sans-serif;
}

.ch-card {
  background-color: #fdc29f;
  border-color: #ff6666;
  border-width: 5px;
  font-weight: bold;
  color: black;
  box-shadow: inset 0px 5px 5px black;
}

.about-menu-button {
  border-color: black;
  border-width: 3px;
  color: black;
  font-weight: bold;
}

.about-menu-button:hover {
  border-color: white;
  border-width: 3px;
  color: white;
  font-weight: bold;
}

#mint {
  background: url('./assets/uranus_large.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  font-family: 'Anonymous', sans-serif;
  background-color: #02d2c0;
}

.total-minted {
  color: white;
}

.mint-widget-mint-button {
  border-color: white;
  border-width: 3px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.mint-widget-mint-button:hover {
  border-width: 3px;
  border-color: white;
  color: white;
  font-weight: bold;
}

.active-mint-button:hover {
  background-color: #615ec5;
}

.mint-widget-connect-button {
  border-color: white;
  border-width: 3px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.mint-widget-connect-button:hover {
  border-width: 3px;
  border-color: white;
  color: white;
  font-weight: bold;
  background-color: #615ec5;
}

.mint-progress {
  padding-top: 1.5rem;
}

#footer {
  background-color: #af0457;
}

@media only screen and (max-width: 575px) {
  .head-social-buttons {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .first-about-card {
    width: 85%;
  }

  .second-about-card {
    width: 85%;
  }

  .third-about-card {
    width: 85%;
  }

  .fourth-about-card {
    width: 85%;
  }

  .third-about {
    width: 75%;
    margin-top: 25px;
  }

  .fourth-about {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .third-about {
    width: 75%;
    margin-top: 25px;
  }

  .fourth-about {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .first-about {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .second-about {
    margin-top: 25px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .third-about {
    margin-top: 25px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .fourth-about {
    margin-top: 25px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .mint-buttons {
    padding-top: 5% !important;
  }
}

@media only screen and (max-width: 305px) {
  .roadmap-nav-button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 202px) {
  .customizer-nav-button {
    margin-top: 10px;
  }
}

.small-mint-col {
  display: none;
}

@media only screen and (max-width: 460px) {
  .mint-background {
    display: none;
  }

  .mint-gifs {
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .large-mint-col {
    display: none;
  }

  .small-mint-col {
    display: block;
  }
}

.first-mint-gif-img {
  border: 2px solid blanchedalmond;
}

.second-mint-gif-img {
  border: 2px solid blanchedalmond;
}

@media only screen and (max-width: 280px) {
  .second-mint-gif {
    display: none;
  }

  .first-mint-gif {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-height: 575px) and (min-height: 401px) {
  #mint {
    background: url('./assets/uranus_large.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 150vh;
    /* font-family: 'Anonymous', sans-serif; */
    background-color: #02d2c0;
  }
}

@media only screen and (max-height: 400px) {
  #mint {
    background: url('./assets/uranus_large.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200vh;
    /* font-family: 'Anonymous', sans-serif; */
    background-color: #02d2c0;
  }
}

.c-progress-bar {
  display: flex;
  align-items: center;
}

.c-progress-bar__indicator {
  width: 100%;
  height: 26px;
  border-radius: 5px;
  border: 2px solid #f4f2f2;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #e5e5e5;
}

.c-progress-bar__indicator-value {
  width: 0px;
  height: 100%;
  border-radius: inherit;
  box-shadow: inherit;
  background-image: repeating-linear-gradient(
    -45deg,
    #708eee,
    #708eee 5px,
    #615ec5 5px,
    #615ec5 10px
  );
  background-size: 28px 28px;
  transition: width 0.5s;
}

.c-progress-bar--is-loading .c-progress-bar__indicator-value {
  -webkit-animation: barberpole 0.5s linear infinite;
  animation: barberpole 0.5s linear infinite;
}

@-webkit-keyframes barberpole {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 14px 0;
  }
}

@keyframes barberpole {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 14px 0;
  }
}

.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.menu-item {
  list-style: none;
}

.menu-link {
  font-size: 1.7rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 1;
  font-weight: 400;
  font-family: 'PEPminus10', sans-serif;
}

.menu-link:hover {
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
  .col-attr-cell {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
  .col-attr-cell {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

/* @media only screen and (max-width: 449px) and (min-width: 400px) {

  .col-attr-cell {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

} */

@media only screen and (max-width: 991px) {
  #ch-generated {
    position: relative;
    top: 20%;
  }

  .col-attr-cell {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .generated-bg-block {
    height: 500px;
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    background-color: rgba(17, 17, 26, 1);
  }
}

@media only screen and (min-width: 992px) {
  #ch-generated {
    position: relative;
    top: 25%;
  }

  .generated-bg-block {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    background-color: rgba(17, 17, 26, 1);
  }
}

@media only screen and (max-width: 399px) {
  #customizer {
    display: none;
  }
}

.active-ch-cell {
  border: 2px solid blanchedalmond;
}

@media only screen and (max-width: 500px) {
  .roadmap-head {
    display: none;
  }

  .roadmap-bottom {
    display: none;
  }

  .lg-transition-to-roadmap {
    display: none;
  }

  #customizer {
    background: rgb(2, 210, 192) !important;
    background: linear-gradient(
      0deg,
      rgba(204, 16, 153, 1) 0%,
      rgba(2, 210, 192, 1) 92%
    ) !important;
  }
}

.ch-generated-border {
  border-radius: 8px;
}

.logo {
  margin: 30px auto 100px;
}

@media (max-width: 1200px) {
  .logo {
    margin: 20px auto 80px;
  }
}

@media (max-width: 767px) {
  .logo {
    margin: 20px auto 60px;
  }

  .menu {
    max-width: 300px;
  }

  .menu-link {
    font-size: 1.4rem;
    margin-bottom: 20px;
    display: inline-block;
  }
}

a {
  transition: 0.3s all;
}
</style>
