<template>
  <div id="about" class="global-header pt-5">
    <div class="art-description pt-3">
      <div class="container pb-5">
        <div class="row justify-content-center">
          <a href="#" v-scroll-to="{ el: '#mint', offset: -20 }">
            <button
              type="button"
              class="btn mx-2 about-menu-button shadow-none mint-nav-button font-weight-normal"
            >
              Mint
            </button>
          </a>
          <a href="#" v-scroll-to="'#customizer'">
            <button
              type="button"
              class="
                btn
                mx-2
                about-menu-button
                shadow-none
                customizer-nav-button
                font-weight-normal
              "
            >
              Customizer
            </button>
          </a>
          <a href="#" v-scroll-to="{ el: '#roadmap', offset: -100 }">
            <button
              type="button"
              class="btn mx-2 about-menu-button shadow-none roadmap-nav-button font-weight-normal"
            >
              Roadmap
            </button>
          </a>
        </div>
      </div>
      <div class="container pt-2 pb-5">
        <div class="row d-flex align-items-stretch">
          <div class="col-lg-3 col-md-6 col-sm-6 first-about">
            <div class="card ch-card mx-auto first-about-card h-100">
              <img
                class="card-img-top mx-auto"
                style="max-width: 300px"
                src="./../assets/sample1.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <p class="card-text text-justify font-weight-normal">
                  Don't peer into its eyes - it'll charm you and leave you with nothing! Uses it's
                  wings to get his own food.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 second-about">
            <div class="card ch-card mx-auto second-about-card h-100">
              <img
                class="card-img-top mx-auto"
                style="max-width: 300px"
                src="./../assets/sample2.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <p class="card-text text-justify font-weight-normal">
                  Its heart is on fire, and its temper is short. You're better off not arguing with
                  it. Loves snacks made of sizzling lava.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 third-about">
            <div class="card ch-card mx-auto third-about-card h-100">
              <img
                class="card-img-top mx-auto"
                style="max-width: 300px"
                src="./../assets/sample3.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <p class="card-text text-justify font-weight-normal">
                  Native to WASP-104b - considered to be one of the darkest exoplanets discovered.
                  Radioactive - which makes it glow a little in the dark.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 fourth-about">
            <div class="card ch-card mx-auto fourth-about-card h-100">
              <img
                class="card-img-top mx-auto"
                style="max-width: 300px"
                src="./../assets/sample4.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <p class="card-text text-justify font-weight-normal">
                  Frightening and fascinating at the same time. Named after the planet where it was
                  first discovered, GJ504b.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style>
</style>