<template>
  <div id="mint">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-11 col-11 large-mint-col">
          <img
            class="img-fluid position-absolute mint-background"
            src="./../assets/chat_large.png"
          />
          <div class="position-absolute mint-widget pb-5">
            <div class="row pt-5 ml-n4 mint-gifs">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 pt-3">
                <img class="img-fluid w-75 ml-5" src="./../assets/gif_with_bg1.gif" />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 pt-3">
                <img class="img-fluid w-75" src="./../assets/gif_with_bg2.gif" />
              </div>
            </div>
            <div class="pt-5 d-flex justify-content-center">
              <h3 class="pr-4 total-minted">{{ app.currMinted }} / 10 000 Minted</h3>
            </div>
            <div class="row mx-5 pt-2 pr-4">
              <div
                class="c-progress-bar w-100 c-progress-bar--is-loading"
                role="progressbar"
                :aria-valuenow="app.currMinted"
                aria-valuemin="0"
                :aria-valuemax="app.totalMinted"
              >
                <div class="c-progress-bar__indicator">
                  <div class="c-progress-bar__indicator-value" :style="app.progress"></div>
                </div>
              </div>
            </div>

            <div class="pt-5 d-flex justify-content-center mint-buttons">
              <button
                class="mint-widget-mint-button btn mx-2 shadow-none"
                :class="{ 'active-mint-button': app.walletConnected }"
                v-bind:disabled="!app.walletConnected"
                @click="app.mint()"
              >
                Mint
              </button>
              <button
                class="mint-widget-connect-button btn mx-2 shadow-none mr-4"
                @click="app.connectWallet()"
              >
                <span v-if="!app.walletConnected">Connect</span>
                <span v-if="app.walletConnected">{{ app.walletAddrFormatted }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 small-mint-col">
          <div class="mint-widget pb-5">
            <div class="row">
              <div class="col-6 first-mint-gif">
                <img class="img-fluid first-mint-gif-img" src="./../assets/gif_with_bg1.gif" />
              </div>
              <div class="col-6 second-mint-gif">
                <img class="img-fluid second-mint-gif-img" src="./../assets/gif_with_bg2.gif" />
              </div>
            </div>

            <div class="pt-5 d-flex justify-content-center">
              <h3 class="total-minted text-center">{{ app.currMinted }} / 10 000 Minted</h3>
            </div>
            <div class="row mx-auto pt-2">
              <div
                class="c-progress-bar w-100 c-progress-bar--is-loading"
                role="progressbar"
                :aria-valuenow="app.currMinted"
                aria-valuemin="0"
                :aria-valuemax="app.totalMinted"
              >
                <div class="c-progress-bar__indicator">
                  <div class="c-progress-bar__indicator-value" :style="app.progress"></div>
                </div>
              </div>
            </div>

            <div class="pt-5 d-flex justify-content-center">
              <button
                class="mint-widget-mint-button btn mx-2 shadow-none"
                :class="{ 'active-mint-button': app.walletConnected }"
                v-bind:disabled="!app.walletConnected"
                @click="app.mint()"
              >
                Mint
              </button>
              <button
                class="mint-widget-connect-button btn mx-2 shadow-none"
                @click="app.connectWallet()"
              >
                <span v-if="!app.walletConnected">Connect</span>
                <span v-if="app.walletConnected">{{ app.walletAddrFormatted }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mint',
  computed: {
    app() {
      return this.$parent.$parent
    }
  }
}
</script>

<style>
</style>