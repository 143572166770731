<template>
  <div class="container">
    <div class="breed-content">
      <div class="breed-select">
        <div v-if="$root.isTablet" class="breed-select__title">Please select NFT</div>
        <div class="breed-select__item" v-for="i in 2" :key="i" @click="select()">
          <template v-if="chosenItems[i - 1]">
            <div class="breed-select__image">
              <div
                v-if="!resultItem"
                class="breed-select__remove"
                @click.stop="remove(i - 1)"
              ></div>
              <img :src="chosenItems[i - 1].img" alt="breed" />
            </div>
            <div v-if="!$root.isTablet" class="breed-select__details">
              <div class="breed-select__detail">
                <p class="breed-select__detail-name">ID</p>
                <span class="breed-select__detail-value" v-html="chosenItems[i - 1].id"></span>
              </div>
              <div class="breed-select__detail">
                <p class="breed-select__detail-name">Rewards</p>
                <span class="breed-select__detail-value" v-html="chosenItems[i - 1].rewards"></span>
              </div>
              <div
                class="breed-select__detail"
                v-for="(attribute, i) in chosenItems[i - 1].attributes"
                :key="i"
              >
                <p class="breed-select__detail-name" v-html="attribute.name"></p>
                <div class="breed-select__values">
                  <span class="breed-select__detail-value" v-html="attribute.value"></span>
                  <span
                    class="breed-select__trait"
                    v-html="attribute.rare + ' have this trait'"
                  ></span>
                </div>
              </div>
            </div>
            <a v-else href="#" class="breed-select__info" @click.stop.prevent="showInfo(i - 1)">
              Info
            </a>
          </template>
          <template v-else>
            <div class="breed-select__image">
              <img src="@/assets/plus.svg" alt="breed" />
            </div>
            <div v-if="!$root.isTablet" class="breed-select__title">Please select NFT</div>
          </template>
        </div>
      </div>
      <template v-if="$root.isTablet">
        <div v-if="chosenItems.length" class="breed-select__fee">Breed Fees: $12</div>
        <button class="button button--breed" :class="{ active: isBreedReady }" @click="breed()">
          {{ btnText }}
        </button>
      </template>
      <div class="breed-result">
        <div class="breed-result__title">Breeding result</div>
        <template v-if="!resultItem">
          <div class="breed-result__image">
            <img src="@/assets/breed-result.png" alt="breed-result" />
          </div>
          <template v-if="!$root.isTablet">
            <div v-if="chosenItems.length" class="breed-select__fee">Breed Fees: $12</div>
            <button class="button button--breed" :class="{ active: isBreedReady }" @click="breed()">
              {{ btnText }}
            </button>
          </template>
        </template>
        <template v-else>
          <div class="breed-result__image">
            <img :src="resultItem.img" alt="breed-result" />
          </div>
          <div v-if="!$root.isTablet" class="breed-select__details ml-0">
            <div class="breed-select__detail">
              <p class="breed-select__detail-name">ID</p>
              <span class="breed-select__detail-value" v-html="resultItem.id"></span>
            </div>
            <div class="breed-select__detail">
              <p class="breed-select__detail-name">Rewards</p>
              <span class="breed-select__detail-value" v-html="resultItem.rewards"></span>
            </div>
            <div
              class="breed-select__detail"
              v-for="(attribute, i) in resultItem.attributes"
              :key="i"
            >
              <p class="breed-select__detail-name" v-html="attribute.name"></p>
              <div class="breed-select__values">
                <span class="breed-select__detail-value" v-html="attribute.value"></span>
                <span
                  class="breed-select__trait"
                  v-html="attribute.rare + ' have this trait'"
                ></span>
              </div>
            </div>
          </div>
          <a v-else href="#" class="breed-select__info" @click.stop.prevent="showInfoBreed()">
            Info
          </a>
        </template>
      </div>
    </div>
    <BreedModal :items="items" :chosenItems="chosenItems" />
    <BreedInfoModal :item="modalItem" />
  </div>
</template>

<script>
import BreedModal from '@/components/BreedModal'
import BreedInfoModal from '@/components/BreedInfoModal'
import Items from '@/assets/Items.js'

export default {
  name: 'BreedContent',
  components: { BreedModal, BreedInfoModal },
  data() {
    return {
      items: Items,
      resultItem: null,
      modalItem: null
    }
  },
  computed: {
    app() {
      return this.$parent.$parent
    },
    chosenItems() {
      let items = this.items.filter(item => {
        return item.selected
      })

      return items ? items : []
    },
    isChosen() {
      return this.chosenItems.length > 1
    },
    isBreedReady() {
      return this.isChosen && this.app.walletConnected
    },
    btnText() {
      if (!this.app.walletConnected) {
        return 'Connect Wallet'
      } else {
        return this.isChosen ? 'Breed' : 'Please select NFT'
      }
    }
  },
  methods: {
    select() {
      if (this.resultItem) {
        return
      }
      this.$root.breedModalVisible = true
    },
    remove(i) {
      if (this.resultItem) {
        return
      }
      this.chosenItems[i].selected = false
    },
    showInfo(i) {
      this.modalItem = this.chosenItems[i]
      this.$root.breedInfoModalVisible = true
    },
    breed() {
      if (!this.app.walletConnected) {
        this.app.connectWallet()
      }
      if (!this.isBreedReady) {
        return
      }
      this.resultItem = this.items[this.items.length - 1]
    },
    showInfoBreed() {
      this.modalItem = this.resultItem
      this.$root.breedInfoModalVisible = true
    }
  }
}
</script>

<style lang="scss">
.breed-content {
  padding: 40px 0 160px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 16px;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-bottom: 120px;
  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
}

.breed-select {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  flex-grow: 1;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
    min-height: 320px;
    height: auto;
    padding: 40px;
    background: #401769;
    border-radius: 12px 12px 0px 0px;
    justify-content: center;
    row-gap: 0;
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    padding: 24px;
    min-height: 220px;
  }
}

.breed-select__item {
  background: #401769;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 320px;
  padding: 60px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background-color: #4f207e;
  }

  &:first-child {
    border-radius: 12px 0px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 0px 12px;
  }

  @media (max-width: 1023px) {
    height: auto;
    padding: 0;
    border-radius: 0 !important;
    width: 182px;
    margin-right: 40px;
    flex-wrap: wrap;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    width: 124px;
    max-width: calc(50% - 8px);
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.breed-select__title {
  margin-left: 24px;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #fff;

  @media (max-width: 1023px) {
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.breed-select__image {
  position: relative;
  width: 182px;
  height: 182px;
  background: #fadbd1;
  border: 5px solid #e44b39;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px #000000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 124px;
    height: 124px;
  }
}

.breed-select__remove {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 24px;
  height: 24px;
  border-radius: 0px 0px 5px 0px;
  background-color: #401769;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  background-position: center center;
  background-image: url(~@/assets/button-close.svg);
  transition: 0.3s all;

  &:hover {
    background-color: #4f207e;
  }
}

.breed-result {
  width: 100%;
  max-width: 480px;
  background: #401769;
  border-radius: 0px 12px 12px 0px;
  padding: 40px;
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
    border-radius: 0px 0px 12px 12px;
  }

  @media (max-width: 767px) {
    padding: 24px;
  }
}

.breed-result__title {
  width: 100%;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.breed-result__image {
  width: 256px;
  height: 256px;
  background: #fadbd1;
  border: 5px solid #e44b39;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px #000000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
}

.breed-select__fee {
  font-family: 'Anonymous Pro';
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 24px;
  color: #ffffff;

  @media (max-width: 1023px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.breed-select__info {
  font-family: 'Anonymous Pro';
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    color: #fff;
    opacity: 0.8;
  }

  @media (max-width: 1023px) {
  }

  @media (max-width: 767px) {
  }
}

.breed-select__details {
  background: #542e79;
  padding: 24px;
  min-width: 320px;
  height: 240px;
  overflow: auto;
  margin-left: 24px;

  & * {
    font-family: 'Anonymous Pro';
  }

  @media (max-width: 1023px) {
    padding: 24px 15px 0 24px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    min-width: auto;
    margin-bottom: 25px;
  }
}

.breed-select__detail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #654286;
  padding-bottom: 12px;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.breed-select__detail-name {
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.breed-select__values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.breed-select__detail-value {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  text-align: right;
}

.breed-select__trait {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #ffffff;
  opacity: 0.5;
}
</style>
