import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Stake from '@/views/Stake'
import Breed from '@/views/Breed'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: 'Nomo Celestial',
        description: 'Nomo Celestial'
      }
    },
    {
      path: '/stake',
      name: 'Stake',
      component: Stake,
      meta: {
        title: 'Nomo Celestial',
        description: 'Nomo Celestial'
      }
    },
    {
      path: '/breed',
      name: 'Breed',
      component: Breed,
      meta: {
        title: 'Nomo Celestial',
        description: 'Nomo Celestial'
      }
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})