<template>
  <div class="table">
    <table v-if="!$root.isTablet">
      <thead>
        <tr class="table__header">
          <th class="table__header-item">
            <p class="table__header-title">Staked token</p>
          </th>
          <th class="table__header-item">
            <p class="table__header-title">Rewards</p>
          </th>
        </tr>
      </thead>
      <tbody class="table__items">
        <tr class="table__row" v-for="(stakedItem, index) in stakedItems" :key="index">
          <td class="table__item">
            <div class="table__item-block">
              <div class="table__pic">
                <img :src="stakedItem.img" alt="avatar" />
              </div>
              <span class="table__item-id" v-html="'ID ' + stakedItem.id"> </span>
              <button class="button button--unstake" @click="stakeToggle(stakedItem)">
                Unstake
              </button>
            </div>
            <div class="table__item-block">
              <span class="table__item-rewards" v-html="stakedItem.rewards"> </span>
              <span class="table__item-harvest" v-html="stakedItem.harvest"> </span>
              <button class="button button--harvest">Harvest</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <div class="table__row" v-for="(stakedItem, index) in stakedItems" :key="index">
        <div class="table__item">
          <div class="table__item-block">
            <div class="table__item-inner">
              <div class="table__pic">
                <img :src="stakedItem.img" alt="avatar" />
              </div>
              <span class="table__item-id" v-html="'ID ' + stakedItem.id"> </span>
            </div>
            <button class="button button--unstake" @click="stakeToggle(stakedItem)">Unstake</button>
          </div>
          <div class="table__item-block">
            <div class="table__item-rewards-wrapper">
              <p>Rewards</p>
              <span v-html="stakedItem.rewards"> </span>
            </div>
            <div class="table__item-harvest-wrapper">
              <span v-html="stakedItem.harvest"> </span>
              <button class="button button--harvest">Harvest</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StakeTable',
  data() {
    return {}
  },
  computed: {},
  methods: {
    stakeToggle(item) {
      item.staked = !item.staked
    }
  },
  props: {
    stakedItems: {
      type: Array
    }
  }
}
</script>

<style lang="scss">
.table {
  width: 100%;
  padding: 0px 60px;
  transition: all 0.3s;

  @media (max-width: 1200px) {
    padding: 0 0 0 60px;
  }

  @media (max-width: 1023px) {
    padding: 0;
  }

  table {
    width: 100%;

    th,
    td {
      padding: 0;
    }

    th {
      border: none;
    }
  }
}

.table__row {
  &:first-of-type {
    @media (max-width: 1023px) {
      .table__item {
        padding: 0 0 24px 0 !important;
        border: none;
      }
    }
  }
}

.table__item-id {
  font-family: 'Anonymous Pro', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  margin: 0;
  color: #ffffff;
  margin-right: 18%;

  @media (max-width: 1023px) {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
}

.table__header-title {
  font-family: 'Anonymous Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.4;
  margin: 0;
}

.table__header {
  display: flex;
  margin-bottom: 14px;
}

.table__header-item {
  &:first-of-type {
    width: 57%;
  }

  &:last-of-type {
    width: 43%;
  }
}

.table__item {
  display: flex;
  align-items: center;
  border-top: none;
  padding: 24.5px 0 !important;

  border-top: 1px solid #654286;

  @media (max-width: 1023px) {
    align-items: baseline;
  }
}

.table__pic {
  width: 42px;
  border-radius: 3px;
  height: 42px;
  background-color: #fadbd1;

  margin-right: 25px;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    margin-right: 15px;
  }

  @media (max-width: 767px) {
    margin-right: 10px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}

.table__item-block {
  display: flex;
  align-items: center;

  &:first-of-type {
    width: 57%;

    @media (max-width: 1023px) {
      // align-items: center;
    }

    @media (max-width: 800px) {
      align-items: flex-start;
    }
  }

  &:last-of-type {
    width: 43%;

    @media (max-width: 1023px) {
      align-items: flex-end;
    }

    @media (max-width: 800px) {
      align-items: flex-end;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: baseline;
  }

  .button--harvest {
    margin: 0;
  }
}

.table__item-inner {
  display: flex;

  @media (max-width: 1023px) {
    align-items: center;
    margin-bottom: 14px;
  }
}

.table__item-rewards-wrapper {
  display: flex;
  align-items: baseline;

  @media (max-width: 1023px) {
    margin-bottom: 14px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
    transform: translateY(-6px);
  }

  p {
    font-family: 'Anonymous Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    margin: 0;
    opacity: 0.4;

    @media (max-width: 1023px) {
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }

  span {
    font-family: 'Anonymous Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: right;

    color: #ffffff;

    @media (max-width: 767px) {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.table__item-harvest-wrapper {
  display: flex;
  margin-top: 16px;
  align-items: center;

  span {
    font-family: 'Anonymous Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: right;

    color: #ffffff;
    margin-right: 5px;

    @media (max-width: 767px) {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.table__item-rewards {
  font-family: 'Anonymous Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;

  margin-right: 29%;
}

.table__item-harvest {
  font-family: 'Anonymous Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 7%;

  color: #ffffff;
}
</style>
