<template>
  <div>
    <img class="w-100 roadmap-head" src="@/assets/afternoon_large_head.png" />

    <div id="roadmap">
      <div class="container-fluid px-0 pb-0">
        <div class="row no-gutters">
          <div class="d-none d-md-block col-md-3">
            <img
              class="left-roadmap"
              src="@/assets/afternoon_large_left.png"
              ref="roadmapLeftImage"
            />
          </div>
          <div class="col-md-5 col-lg-4 mx-auto mt-5 pt-5">
            <div id="roadmap-content" v-resize @resize="onResize" ref="roadmapContent">
              <div class="mb-5 pb-5">
                <h2 class="text-center font-weight-bold display-3 roadmap-caption">
                  <span style="color: white">Road</span><span style="color: #663399">Map</span>
                </h2>
              </div>

              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    1
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>Art Drop</h3>
                    <p class="font-weight-light my-3">
                      Genesis collection drop on Fantom blockchain - 10.000 randomly generated NFTs
                      with more than 1MM possible combinations
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    2
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>$NOMO</h3>
                    <p class="font-weight-light my-3">
                      Introducing $NOMO token to serve internal project needs such as
                      upgrading/customizing Nomoes and participating in Nomo Universe voting
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    3
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>Play-To-Earn</h3>
                    <p class="font-weight-light my-3">
                      Stake your Nomoes and receive $NOMO tokens. Buy new attributes, wear, and
                      increase your collectible rarity. Mint NFTs for $NOMO after genesis drop ends
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    4
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>Attributes shop release</h3>
                    <p class="font-weight-light my-3">
                      Everyone would be able to buy new clothes, armor and weapons for their Nomoes.
                      Create your own perfectly-looking setup!
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    5
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>Forming a DAO</h3>
                    <p class="font-weight-light my-3">
                      Community decides on development direction. Community members are being
                      incentivized to take part in the project life
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="bg-light position-relative px-3 my-5">
                  <div
                    class="
                      font-weight-bold
                      circle
                      text-white
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                      mx-auto
                      position-relative
                      border border-white
                    "
                    style="
                      width: 60px;
                      height: 60px;
                      top: -30px;
                      border-width: 4px !important;
                      background-color: #663399;
                      font-family: 'Anonymous', sans-serif;
                    "
                  >
                    6
                  </div>
                  <div class="px-3 text-center pb-3">
                    <h3>3D Art</h3>
                    <p class="font-weight-light my-3">
                      Introducing 3D versions of existing genesis NFTs. This step would allow art
                      owners to participate in the evolving metaverse habitat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-none d-md-block col-md-3 text-right">
            <img
              class="right-roadmap"
              src="@/assets/afternoon_large_right.png"
              ref="roadmapRightImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Roadmap',
  methods: {
    onResize() {
      this.$refs.roadmapLeftImage.style.height = this.$refs.roadmapContent.clientHeight + 180 + 'px'
      this.$refs.roadmapRightImage.style.height =
        this.$refs.roadmapContent.clientHeight + 180 + 'px'
    }
  }
}
</script>

<style scoped>
#roadmap {
  background-color: #cc1099;
  font-family: 'PEPminus10', sans-serif;
}

.left-roadmap {
  width: 55.5%;
  object-fit: cover;
  object-position: 100% 0;
}

.right-roadmap {
  width: 55.5%;
  object-fit: cover;
  object-position: 100% 0;
}

@media only screen and (max-width: 330px) {
  .roadmap-caption {
    font-size: 2.5em;
  }
}

@media only screen and (min-width: 501px) {
  .sm-transition-to-roadmap {
    display: none;
  }
}
</style>