<template>
  <div class="stake-content">
    <div class="container">
      <div class="stake-tabs">
        <div class="stake-tabs__titles">
          <a
            href="#"
            @click.prevent="$switchActive(t, tabs)"
            v-for="(tab, t) in tabs"
            :key="t"
            :class="{ active: tab.active }"
          >
            {{ tab.title }}
          </a>
        </div>
        <div
          class="stake-tabs-content"
          v-for="(tab, t) in tabs"
          :key="t"
          :class="{ active: tab.active }"
        >
          <template v-if="tab.name === 'all'">
            <div
              class="stake-tabs-item"
              v-for="(item, i) in items"
              @click="stakeModalOpen(i)"
              :key="i"
            >
              <div class="stake-tabs-item-img">
                <img :src="item.img" alt="item" />
              </div>
              <div class="stake-tabs-item__id">
                <p>ID</p>
                <p v-html="item.id"></p>
              </div>
              <p class="stake-tabs-item__status" v-if="item.staked">Staked</p>
            </div>
          </template>
          <template v-else-if="tab.name === 'staked'">
            <StakeTable :stakedItems="stakedItems" />
          </template>
        </div>
      </div>
    </div>
    <StakeModal :modalItem="modalItem" />
  </div>
</template>

<script>
import StakeModal from './../components/StakeModal'
import StakeTable from '@/components/StakeTable'
import Items from '@/assets/Items.js'

export default {
  name: 'StakeContent',
  components: {
    StakeModal,
    StakeTable
  },
  data() {
    return {
      tabs: [
        {
          title: 'All NFT’s',
          active: true,
          name: 'all'
        },
        {
          title: 'Staked',
          active: false,
          name: 'staked'
        }
      ],
      items: Items
    }
  },
  methods: {
    stakeModalOpen(i) {
      this.items.forEach(item => {
        item.view = false
      })
      this.items[i].view = true
      this.$root.stakeModalVisible = true
    }
  },
  computed: {
    app() {
      return this.$parent.$parent
    },
    modalItem() {
      return this.items.filter(item => {
        return item.view
      })[0]
    },
    stakedItems() {
      let items = []

      this.items.forEach(item => {
        item.staked ? items.push(item) : false
      })

      return items
    }
  },
  created() {}
}
</script>

<style lang="scss">
.stake-content {
  background: linear-gradient(180deg, #02d2c0 0%, #663399 30.2%);
  padding: 132px 0 160px;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 60px 0;
  }
}

.stake-tabs {
  background-color: #4f207e;
  position: relative;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.stake-tabs__titles {
  height: 80px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    height: 60px;
    margin: 0 -24px;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    font-family: 'PEPminus10';
    font-weight: 400;
    line-height: 1;
    font-size: 32px;
    justify-content: center;
    background-color: #401769;
    color: #ffffff80;
    transition: none !important;

    @media (max-width: 767px) {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;

      text-align: center;
    }

    &:before {
      display: none;
    }

    &.active {
      color: #fff;
      background-color: #4f207e;

      &:before {
        display: block;
      }

      &:last-child {
        &:after {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: #fff;
    }

    &:first-child {
      @media (max-width: 767px) {
      }

      &:before {
        content: '';
        border-left: 120px solid #401769;
        border-top: 80px solid transparent;
        right: -120px;
        top: 0;
        position: absolute;
        z-index: 1;
        transition: 0.3s all;

        @media (max-width: 767px) {
          border-left: 60px solid #401769;
          border-top: 60px solid transparent;
          top: 26px;
          right: -60px;
          transform: rotate(30deg);
        }
      }
    }
    &:last-child {
      @media (max-width: 767px) {
      }

      &:before {
        content: '';
        border-right: 120px solid #401769;
        border-top: 80px solid transparent;
        left: -120px;
        top: 0;
        position: absolute;
        z-index: 1;
        transition: 0.3s all;
        @media (max-width: 767px) {
          border-right: 60px solid #401769;
          border-top: 60px solid transparent;
          top: 26px;
          left: -60px;
          transform: rotate(-30deg);
        }
      }
    }
    &:first-child {
      &:before {
        border-left: 120px solid #4f207e;

        @media (max-width: 767px) {
          top: 16px;
          border-left: 80px solid #4f207e;
        }
      }
    }
    &:last-child {
      &:before {
        border-right: 120px solid #4f207e;

        @media (max-width: 767px) {
          top: 16px;
          border-right: 80px solid #4f207e;
        }
      }

      &:after {
        @media (max-width: 767px) {
          position: absolute;
          content: '';
          height: 100%;
          width: 50px;
          background-color: #401769;
          right: -50px;
          top: 0;
        }
      }
    }
  }
}

.stake-tabs-content {
  margin: 50px 80px;
  margin-bottom: 40px;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: 0.3s all;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  &.active {
    position: initial;
    top: auto;
    z-index: auto;
    opacity: 1;

    .stake-tabs-item {
      height: auto;
    }
  }

  @media (max-width: 1023px) {
    margin: 40px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin: 30px 0;
    margin-bottom: 20px;
  }
}

.stake-tabs-item__id {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  p {
    font-family: 'Anonymous Pro', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin: 0;

    color: #ffffff;

    &:first-of-type {
      margin: 0 10px 0 0;
    }
  }
}

.stake-tabs-item__status {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 8px 0 0 0;

  text-align: center;
  letter-spacing: 0.04em;

  color: #e44b39;
}

.stake-tabs-item {
  width: 23%;
  height: 0;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1023px) {
    width: 30%;
    margin-bottom: 22px;
  }

  @media (max-width: 767px) {
    width: calc(50% - 10px);
    margin-bottom: 28px;
  }

  &-img {
    width: 100%;
    height: 220px;
    background: #fadbd1;
    border: 5px solid #e44b39;
    box-sizing: border-box;
    box-shadow: inset 0px 3px 3px #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      height: 180px;
    }

    @media (max-width: 767px) {
      height: 190px;
      max-width: 200px;
      margin: 0 auto;
    }

    @media (max-width: 450px) {
      height: 170px;
    }

    @media (max-width: 360px) {
      height: 150px;
    }

    img {
      width: 170px;
      height: 150px;
      object-fit: contain;
    }
  }
}
</style>
