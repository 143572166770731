<template>
  <div class="breed-header">
    <div class="container">
      <router-link to="/" class="breed-header__logo">
        <img src="@/assets/logo.png" alt="logo" />
      </router-link>
      <h1 class="breed-header__title">Breed</h1>
      <div class="breed-header__text">
        Introducing $NOMO token to serve internal project needs such as upgrading/customizing Nomoes
        and participating in Nomo Universe voting. Everyone would be able to buy new clothes, armor
        and weapons.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreedHeader',
  computed: {
    app() {
      return this.$parent.$parent
    }
  }
}
</script>

<style lang="scss">
.breed-header {
  position: relative;
}

.breed-header__logo {
  width: 151px;
  height: 40px;
  margin: 0 auto;
  padding: 30px 0 120px;
  display: block;

  @media (max-width: 1023px) {
    padding: 30px 0 100px;
  }

  @media (max-width: 767px) {
    width: 120px;
    height: 32px;
    padding: 20px 0 80px;
  }

  img {
    object-fit: contain;
    width: 100%;
  }
}

.breed-header__title {
  font-size: 96px;
  line-height: 90%;
  color: #fff;
  width: 100%;
  margin-top: 0;
  text-align: left;

  @media (max-width: 1023px) {
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 60px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    font-style: normal;
    font-weight: normal;
    // font-size: 50px;
    // line-height: 50px;
  }
}

.breed-header__text {
  max-width: 640px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Anonymous Pro';
}
</style>
