<template>
  <main id="breed">
    <BreedHeader></BreedHeader>
    <BreedContent></BreedContent>
    <FooterMain></FooterMain>
  </main>
</template>

<script>
import BreedHeader from './../components/BreedHeader'
import BreedContent from './../components/BreedContent'
import FooterMain from './../components/Footer'

export default {
  name: 'Breed',
  components: {
    BreedHeader,
    BreedContent,
    FooterMain
  },
  props: {},
  data() {
    return {}
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss">
#breed {
  background: linear-gradient(180deg, #615ec5 0%, #02d2c0 100%);
}
</style>