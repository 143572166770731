<template>
  <div class="breed-modal" v-if="$root.breedModalVisible && items">
    <div class="breed-modal__overlay" @click.self="closeModal()">
      <div class="breed-modal__body">
        <button class="button button--close" @click="closeModal()"></button>
        <section class="breed-modal__content">
          <h2 class="breed-modal__title">Please select NFT</h2>
          <div class="breed-table">
            <div class="breed-table__row" v-for="(item, index) in items" :key="index">
              <div class="breed-table__item">
                <div class="breed-table__pic">
                  <img :src="item.img" alt="avatar" />
                </div>
                <span class="breed-table__id" v-html="'ID ' + item.id"> </span>
              </div>
              <div class="breed-table__item">
                <button
                  v-if="chosenItems.length < 2"
                  class="button button--select"
                  :class="{ active: item.selected }"
                  @click="toggle(item)"
                >
                  {{ item.selected ? 'Remove' : 'Select' }}
                </button>
                <button
                  v-else-if="item.selected"
                  class="button button--select"
                  :class="{ active: item.selected }"
                  @click="toggle(item)"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreedModal',
  data() {
    return {}
  },
  props: {
    items: Array,
    chosenItems: Array
  },
  computed: {},
  created() {
    document.addEventListener('keyup', evt => {
      if (evt.key === 27) {
        this.closeModal()
      }
    })
  },
  methods: {
    closeModal() {
      this.$root.breedModalVisible = false
    },
    toggle(item) {
      item.selected = !item.selected
    }
  },
  watch: {},
  components: {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {}
}
</script>

<style lang="scss">
.breed-modal__overlay {
  position: fixed;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.breed-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.breed-modal__body {
  margin: auto;
  overflow-x: auto;
  width: 68%;
  max-width: 980px;
  background: #4f207e;
  min-width: 928px;
  transition: all 0.3s;

  padding: 80px;

  display: flex;
  position: relative;
  align-items: center;
  max-height: fit-content;

  max-height: calc(100vh - 100px);

  @media (max-width: 1023px) {
    align-items: baseline;
    min-width: 608px;
    max-height: calc(100vh - 65px);
    padding: 80px 40px;
  }

  @media (max-width: 767px) {
    min-width: auto;
    max-height: none;
    width: 100%;
    height: 112vh;
    padding: 70px 24px;
    overflow: hidden;
  }

  p,
  span {
    font-family: 'Anonymous Pro', sans-serif;
  }
}

.breed-modal__title {
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.breed-modal__content {
  width: 100%;
}

.breed-table {
  min-height: 400px;
  height: 400px;
  padding: 40px;
  background: #401769;
  border-radius: 8px;
  overflow-y: auto;

  @media (max-width: 1023px) {
    padding: 40px;
    max-height: 450px;
    height: auto;
  }

  @media (max-width: 767px) {
    max-height: 550px;
    padding: 16px;
  }
}

.breed-table__row {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #654286;

  &:first-child {
    padding-top: 0;
  }
}

.breed-table__item {
  display: flex;
  align-items: center;
}

.breed-table__pic {
  width: 42px;
  border-radius: 3px;
  height: 42px;
  background-color: #fadbd1;
  margin-right: 25px;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    margin-right: 15px;
  }

  @media (max-width: 767px) {
    margin-right: 10px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}

.breed-table__id {
  font-family: 'Anonymous Pro', sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  margin: 0;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
