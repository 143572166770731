<template>
  <div class="stake-header">
    <div class="container">
      <router-link to="/" class="stake-header__logo">
        <img src="@/assets/logo.png" alt="logo" />
      </router-link>

      <div class="stake-header__wrapper">
        <div class="stake-header__info">
          <div class="stake-header__info-row">
            <span>Balance NFT</span>
            <span>123</span>
          </div>
          <div class="stake-header__info-row">
            <span>Total earnings</span>
            <span>742.66</span>
          </div>
          <div class="stake-header__info-row">
            <span>Rewards</span>
            <span>10.5121</span>
          </div>
          <button type="button" class="button stake-header__btn" @click="app.connectWallet()">
            <template v-if="!app.walletConnected">Connect wallet</template>
            <template v-else>{{ app.walletAddrFormatted }}</template>
          </button>
        </div>

        <h1 class="stake-header__title">Stake here<br />to earn<br />more!</h1>
      </div>
    </div>
    <div class="stake-bg"></div>
  </div>
</template>

<script>
export default {
  name: 'StakeHeader',
  computed: {
    app() {
      return this.$parent.$parent
    }
  }
}
</script>

<style lang="scss">
.stake-header {
  min-height: 720px;
  background-color: #02d2c0;
  position: relative;

  @media (max-width: 1023px) {
    min-height: 558px;
  }

  @media (max-width: 767px) {
    min-height: 640px;
  }
}

.stake-header__logo {
  width: 151px;
  height: 40px;
  margin: 0 auto;
  padding: 30px 0 140px;
  display: block;

  @media (max-width: 1023px) {
    padding: 30px 0 120px;
  }

  @media (max-width: 767px) {
    width: 120px;
    height: 32px;
    padding: 20px 0 93px;
  }

  img {
    object-fit: contain;
    width: 100%;
  }
}

.stake-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (max-width: 800px) {
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.stake-header__title {
  font-size: 96px;
  line-height: 90%;
  color: #fff;
  width: 47%;
  margin-top: 0;

  @media (max-width: 1023px) {
    font-size: 50px;
    text-align: left;
    width: 33%;
    min-width: 290px;

    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 87%;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    order: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 44px;
    width: 100%;
    text-align: center;
  }
}

.stake-header__btn {
  font-size: 20px;
  font-weight: 400;
  width: 100%;

  border-width: 3px;
  border-color: black;
  color: black;
  font-weight: bold;
  background: #fadbd1;

  padding: 14px 0;
  margin-top: 20px;

  &:hover {
    background: #ffffff;
  }

  @media (max-width: 1023px) {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    text-align: right;
    letter-spacing: 0.04em;
    margin-top: 0;
    text-align: center;

    padding: 11px 0;

    color: #000000;
  }
}

.stake-bg {
  position: absolute;
  height: 100px;
  width: 100%;
  left: 0;
  bottom: -15px;
  background-image: url(~@/assets/stake_head.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;

  @media (max-width: 1023px) {
    height: 60px;
    bottom: 20px;
  }

  @media (max-width: 767px) {
    height: 50px;
    bottom: 0px;
  }
}

.stake-header__info {
  width: 510px;
  min-height: 330px;
  margin-right: 9%;
  padding: 40px 64px 64px 40px;
  background-image: url(~@/assets/stake-info-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    padding: 36px 55px 35px 32px;
    width: 312px;
    min-height: 246px;
    margin-right: 7%;
    background-image: url(~@/assets/stake-info-bg-mobile.png);
  }

  @media (max-width: 767px) {
    order: 2;
    width: 310px;
    margin-right: 0;
    margin-top: 33px;
    padding: 35px 54px 35px 35px;
  }
}

.stake-header__info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 14px;

  @media (max-width: 1023px) {
  }

  span {
    &:first-child {
      font-family: 'Anonymous Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;

      @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &:last-child {
      font-weight: 400;
      font-size: 40px;
      line-height: 1;

      @media (max-width: 1023px) {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        text-align: right;
      }
    }
  }
}
</style>
