export default [{
    id: '5425ddf354g',
    img: require('./../assets/sample5.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample6.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample7.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample8.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample5.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample6.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample7.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample8.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample5.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample6.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample7.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample8.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample5.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample6.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample7.png'),
    staked: false,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  },
  {
    id: '5425ddf354g',
    img: require('./../assets/sample8.png'),
    staked: true,
    view: false,
    selected: false,
    rewards: 10.5121,
    harvest: 654.321,
    attributes: [{
        name: 'Accessory',
        value: 'T-shirt',
        rare: '2%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      },
      {
        name: 'Accessory',
        value: 'Glasses',
        rare: '7%'
      },
      {
        name: 'Type',
        value: 'Male',
        rare: '5%'
      }
    ]
  }
]